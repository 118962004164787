import React, { useRef } from "react";
import { Link } from "react-router-dom";

import background from "../assets/images/business/bg03.jpg"
import { Parallax } from 'react-parallax';
import Navbar from "../componants/indexJs/navbar";

import { BsTelephone, FiMail, FiMapPin } from "../assets/icons/vander"
import Footer from "../componants/footer/footer";
import ScrollTop from "../componants/scrollTop";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function ContactOne() {
    

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_i5o1m2h', 'template_e5ouc4n', form.current, {
                publicKey: '45LgiSPqEWfL3jcfh',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    toast.success('Email sent successfully!');
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    toast.error('Failed to send email. Please try again later.');
                },
            );
    };


    return (
        <>
            <Navbar navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light" logoLight={true} />
            <section className="position-relative overflow-hidden">
                <Parallax
                    blur={{ min: 0, max: 0 }}
                    bgImage={background}
                    bgImageAlt="the dog"
                    strength={500}
                    bgportfolioImageize="100%"
                    bgStyle={{ with: "auto", height: "100%" }}
                    style={{ position: "absolute", width: "100%", height: "100%" }}
                >
                </Parallax>
                <div className="bg-overlay bg-gradient-overlay-2"></div>
                <div className="bg-half-170 d-table w-100">
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="title-heading text-center">
                                    <p className="text-white-50 para-desc mx-auto mb-0">Faites le pas !</p>
                                    <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Contactez-nous !</h5>
                                </div>
                            </div>
                        </div>

                        <div className="position-middle-bottom">
                            <nav aria-label="breadcrumb" className="d-block">
                                <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                    <li className="breadcrumb-item"><Link to="/">FADI</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Nous contacter</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="section pb-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="p-4 rounded shadow">

                                <form ref={form} onSubmit={sendEmail}>
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Votre Nom <span className="text-danger">*</span></label>
                                                <input name="from_name" id="name" type="text" className="form-control" placeholder="Nom :" required />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Votre adresse Email <span className="text-danger">*</span></label>
                                                <input name="reply_to" id="email" type="email" className="form-control" placeholder="Email : " required />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Objet</label>
                                                <input name="subject" id="subject" className="form-control" placeholder="Objet :" required />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Message <span className="text-danger">*</span></label>
                                                <textarea name="message" id="comments" rows="4" className="form-control" placeholder="Message :" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-grid">
                                                <button type="submit" id="submit" name="send" className="btn btn-primary">Envoyer le message</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <ToastContainer />

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mt-60">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="card border-0 text-center features feature-primary feature-clean">
                                <div className="icons bg-lg text-center rounded-lg  mx-auto">
                                    <BsTelephone className="icon d-block h3 mb-0" />
                                </div>

                                <div className="content mt-4 pt-2 px-4">
                                    <h5 className="mb-3">Telephone</h5>
                                    {/* <p className="text-muted">Start working with Fronter that can provide everything</p> */}
                                    <Link to="tel:+22890073949" className="link">+228 90-07-39-49</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mt-5 mt-sm-0">
                            <div className="card border-0 text-center features feature-primary feature-clean">
                                <div className="icons bg-lg text-center rounded-lg  mx-auto">
                                    <FiMail className="icon d-block h3 mb-0" />
                                </div>

                                <div className="content mt-4 pt-2 px-4">
                                    <h5 className="mb-3">Email</h5>
                                    {/* <p className="text-muted">Start working with Fronter that can provide everything</p> */}
                                    <Link to="mailto:fadi.africa@gmail.com" className="link">fadi.africa@gmail.com</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mt-5 mt-lg-0">
                            <div className="card border-0 text-center features feature-primary feature-clean">
                                <div className="icons bg-lg text-center mx-auto rounded-lg ">
                                    <FiMapPin className="icon d-block h3 mb-0" />
                                </div>

                                <div className="content mt-4 pt-2 px-4">
                                    <h5 className="mb-3">Location</h5>
                                    {/* <p className="text-muted">C/54 Northwest Freeway, Suite 558, <br />Houston, USA 485</p> */}
                                    <Link to="https://maps.app.goo.gl/h8i2ZnYtMHCwq5pq8" className="lightbox text-primary" data-type="iframe" data-group="iframe" data-width="1024px" data-height="576px">Voir sur google maps</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-100 mt-60">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="card map border-0">
                                <div className="card-body p-0">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.613536239251!2d1.2030616758913844!3d6.182449093805054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1023e175ee741fa3%3A0xb38051fe625f3244!2sFondation%20Afrique%20D%C3%A9veloppement%20International!5e0!3m2!1sen!2sin!4v1720082326448!5m2!1sen!2sin" title="myfram" style={{ border: "0" }} allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    )
}