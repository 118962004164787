import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/LogoFADIHD1.jpg"
import logoBg from "../../assets/images/LogoFADIHD1.png"

import {AiFillLinkedin,BiLogoFacebook,FiMail} from "../../assets/icons/vander"

export default function Footer(){
    return(
        <footer className="bg-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-py-60 footer-border">
                            <div className="row">
                                <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <Link to="#" className="logo-footer">
                                        <img src={logoBg} style={{ height: '100px'}} alt=""/>
                                    </Link>
                                    <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                        <li className="list-inline-item me-1"><Link to="https://www.linkedin.com/company/fondation-afrique-d%C3%A9veloppement-international/" target="_blank" className="rounded"><AiFillLinkedin/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="https://www.facebook.com/" target="_blank" className="rounded"><BiLogoFacebook/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="mailto:fadi.africa@gmail.com" className="rounded"><FiMail/></Link></li>
                                        {/* <li className="list-inline-item me-1"><Link to="https://forms.gle/QkTueCikDGqJnbky9" target="_blank" className="rounded"><AiOutlineFile/></Link></li> */}
                                    </ul>
                                </div>
                                
                                <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Informations</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li className="text-foot"> 246, Rue Gerard Dulphy</li>
                                        <li className="text-foot"> Aflao Gakli - Lomegan</li>
                                        <li className="text-foot"> 61528 Lome - TOGO</li>
                                    </ul>
                                </div>
                                
                                
            
                                <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Commencer a travailler avec FADI</h5>
                                    <img src={logo} style={{ height: '100px', borderRadius: '100%'}} className="mt-4" alt=""/>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-py-30 footer-bar">
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                            <div className="text-center">
                                <p className="mb-0">© {new Date().getFullYear()} Fondation Afrique-Developpement Internationnal</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}