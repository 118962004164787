import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import logo from "../../assets/images/LogoFADIHD1.jpg"
import logoBg from "../../assets/images/LogoFADIHD1.png"


import { AiOutlineArrowUp, FiShoppingCart } from "../../assets/icons/vander"

export default function Navbar({ navClass, manuClass, logoLight, smallButton }) {
    const [scroll, setScroll] = useState(false);
    const [isMenu, setisMenu] = useState(false);


    var mybutton = document.getElementById("back-to-top");
    window.onscroll = function () {
        scrollFunction();
    };

    function scrollFunction() {
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }
    }

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    // Toggle menu
    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };

    //Menu Active
    function getClosest(elem, selector) {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };

    function activateMenu() {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }


    // Clickable Menu
    if (document.getElementById("navigation")) {
        var elements = document.getElementById("navigation").getElementsByTagName("a");
        for (var i = 0, len = elements.length; i < len; i++) {
            elements[i].onclick = function (elem) {
                if (elem.target.getAttribute("href") === "#") {
                    var submenu = elem.target.nextElementSibling.nextElementSibling;
                    submenu.classList.toggle('open');
                }
            }
        }
    }

    useEffect(() => {
        activateMenu()
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    return (
        <>
            <header id="topnav" className={`${scroll ? "nav-sticky" : ""} ${navClass}`}>
                <div className="container">
                    {logoLight === true ?
                        <Link className="logo" to="/home">
                            <span className="logo-light-mode">
                                <img src={logoBg} className="l-dark" style={{ height: '50px', borderRadius: '100%'}} alt="" />
                                <img src={logo} className="l-light" style={{ height: '50px', borderRadius: '100%'}} alt="" />
                                {/* <img src={logolight} className="l-light" alt="" /> */}
                            </span>
                            <img src={logo} className="logo-dark-mode" style={{ height: '50px', borderRadius: '100%'}} alt="" />
                        </Link> :

                        <Link className="logo" to="/home">
                            <img src={logo} className="logo-light-mode" style={{ height: '50px', borderRadius: '100%'}} alt="" />
                            <img src={logoBg} className="logo-dark-mode" style={{ height: '50px', borderRadius: '100%'}} alt="" />
                        </Link>
                    }

                    <div className="menu-extras">
                        <div className="menu-item">
                            <Link className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* {smallButton === false ?
                        <ul className="buy-button list-inline mb-0">
                            <li className="list-inline-item ps-1 mb-0">
                                <Link to="https://1.envato.market/fronter-react" target="_blank">
                                    <div className="btn btn-icon btn-pills btn-primary d-sm-none d-inline-flex"><FiShoppingCart /></div>
                                    <div className="btn btn-primary d-none d-sm-block">Download Now</div>
                                </Link>
                            </li>
                        </ul> :

                        <ul className="buy-button list-inline mb-0">
                            <li className="list-inline-item ps-1 mb-0">
                                <Link to="#" target="_blank">
                                    <div className="btn btn-pills btn-primary d-none d-sm-block"><FiShoppingCart /> Nous contacter</div>
                                </Link>
                            </li>
                        </ul>
                    } */}

                    <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                        <ul className={manuClass}>
                            <li><Link to="/home" className="sub-menu-item">Accueil</Link></li>
                            <li><Link to="https://blog.fadi-fondation.org" className="sub-menu-item">Blog</Link></li>
                            <li><Link to="/contact" className="sub-menu-item">Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </header>
            <Link to="#" onClick={topFunction} id="back-to-top" className="back-to-top rounded-pill fs-5"><AiOutlineArrowUp className="fea icon-sm icons align-middle" /></Link>
        </>
    )
}