import sante from '../assets/images/business/sante.jpg';
import environnement from '../assets/images/business/environement.jpg';
import pauvrete from '../assets/images/business/pauvrete.jpg';
import education from '../assets/images/business/education.jpg';
import genre from '../assets/images/business/gender.jpg';
import paix from '../assets/images/business/peace.jpg';
import action from '../assets/images/business/action.jpg';
import formation from '../assets/images/business/formation.jpg';

import {HiOutlinePresentationChartLine,LiaFileInvoiceDollarSolid,AiOutlineMoneyCollect,RiPresentationLine,AiOutlineDollarCircle,RiFileList3Line, FaHandsHelping, RiSpeakFill, BsFillCalendarDateFill, GiPeaceDove} from "../assets/icons/vander"

export const featuresData = [
    {
        id: 1,
        icon: HiOutlinePresentationChartLine,
        title: "Éducation",
        desc: "L'éducation est un droit fondamental qui doit être accessible à tous les enfants, quels que soient leur origine ou leur milieu socio-économique. Nous nous engageons à contribuer à la mise en place des infrastructures scolaires nécessaires pour offrir un environnement propice à l'apprentissage. Nos efforts se concentrent sur la construction et la rénovation d'écoles dans les zones rurales, ainsi que sur la fourniture de matériel pédagogique adéquat. En outre, nous mettons en place des programmes de formation continue pour les enseignants afin d'améliorer la qualité de l'enseignement dispensé.",
        activities: [
            "Construction et rénovation d'écoles en milieu rural.",
            "Fourniture de matériel pédagogique aux élèves défavorisés.",
            "Formation continue pour les enseignants."
        ],
        image: education
    },
    {
        id: 2,
        icon: LiaFileInvoiceDollarSolid,
        title: "Formation",
        desc: "L'alphabétisation des adultes est cruciale pour leur intégration socio-économique et leur épanouissement personnel. Nous proposons des cours d'alphabétisation pour les adultes, hommes et femmes, afin de leur permettre d'acquérir des compétences de base en lecture et en écriture. Par ailleurs, nous organisons des ateliers de formation continue pour l'actualisation des connaissances et le développement de nouvelles compétences professionnelles. Ces formations couvrent divers domaines, tels que la gestion d'entreprise, les technologies de l'information et les compétences techniques spécifiques à différents secteurs.",
        activities: [
            "Organisation de cours d'alphabétisation pour adultes.",
            "Ateliers de formation en compétences professionnelles.",
            "Programmes de mentorat et de développement personnel."
        ],
        image: formation
    },
    {
        id: 3,
        icon: AiOutlineMoneyCollect,
        title: "Lutte contre la pauvreté",
        desc: "La lutte contre la pauvreté nécessite des initiatives coordonnées et durables pour fournir des ressources et des opportunités économiques aux communautés défavorisées. Nous mettons en œuvre des programmes de microcrédit pour aider les petites entreprises à se développer et à prospérer. En outre, nous offrons des formations en entrepreneuriat et en gestion financière pour renforcer les compétences des individus et les aider à devenir autonomes. Nos programmes de soutien incluent également la fourniture de nourriture et de logement aux familles dans le besoin.",
        activities: [
            "Microcrédits pour les petites entreprises.",
            "Formations en entrepreneuriat et gestion financière.",
            "Programmes de soutien alimentaire et de logement."
        ],
        image: pauvrete
    },
    {
        id: 4,
        icon: RiPresentationLine,
        title: "Promotion du genre",
        desc: "Nous croyons fermement en l'égalité des sexes et en l'autonomisation des femmes. Nos initiatives visent à sensibiliser le public à l'importance de l'égalité des genres et à soutenir les femmes dans leur développement personnel et professionnel. Nous organisons des campagnes de sensibilisation, des formations en leadership pour les femmes et des programmes de soutien aux entreprises dirigées par des femmes. En promouvant l'égalité des genres, nous contribuons à créer une société plus juste et équitable pour tous.",
        activities: [
            "Campagnes de sensibilisation sur l'égalité des genres.",
            "Formation en leadership pour les femmes.",
            "Soutien aux entreprises dirigées par des femmes."
        ],
        image: genre
    },
    {
        id: 5,
        icon: AiOutlineDollarCircle,
        title: "Santé",
        desc: "L'accès aux soins de santé est essentiel pour le bien-être des communautés. Nous améliorons cet accès en mettant en place des cliniques mobiles qui offrent des soins gratuits dans les régions éloignées et mal desservies. Nos programmes de santé incluent également des campagnes de vaccination et de prévention des maladies, ainsi que des initiatives d'éducation à la santé et à l'hygiène. En fournissant des soins de santé de qualité, nous contribuons à améliorer la qualité de vie des individus et à réduire les inégalités en matière de santé.",
        activities: [
            "Cliniques mobiles offrant des soins gratuits.",
            "Programmes de vaccination et de prévention des maladies.",
            "Éducation à la santé et hygiène."
        ],
        image: sante
    },
    {
        id: 6,
        icon: RiFileList3Line,
        title: "Paix et résolution de conflits",
        desc: "La promotion de la paix et la résolution des conflits sont essentielles pour construire des communautés résilientes et harmonieuses. Nous organisons des ateliers de médiation et de résolution de conflits pour aider les individus et les groupes à gérer les désaccords de manière pacifique et constructive. Nos programmes de réconciliation communautaire visent à restaurer la confiance et à renforcer les relations entre les membres des communautés. En formant les leaders locaux à la gestion des conflits, nous contribuons à la prévention des violences et à la promotion de la paix durable.",
        activities: [
            "Ateliers de médiation et de résolution de conflits.",
            "Programmes de réconciliation communautaire.",
            "Formation en gestion des conflits pour les leaders locaux."
        ],
        image: paix
    },
    {
        id: 7,
        icon: RiFileList3Line,
        title: "Actions humanitaires",
        desc: "En réponse aux crises et aux catastrophes naturelles, nous fournissons une assistance humanitaire pour soutenir les communautés affectées. Nos actions incluent la distribution de vivres et de fournitures essentielles, ainsi que des programmes de réhabilitation post-catastrophe pour aider les familles à se reconstruire. Nous apportons également notre soutien aux réfugiés et aux déplacés internes en leur fournissant un abri, des soins de santé et une aide psychosociale. Notre objectif est de répondre rapidement et efficacement aux besoins des personnes en situation de détresse.",
        activities: [
            "Distribution de vivres et de fournitures essentielles.",
            "Programmes de réhabilitation post-catastrophe.",
            "Soutien aux réfugiés et aux déplacés internes."
        ],
        image: action
    },
    {
        id: 8,
        icon: RiFileList3Line,
        title: "Environnement",
        desc: "La protection de l'environnement et la durabilité sont au cœur de nos préoccupations. Nous menons des projets de reboisement et de conservation des forêts pour lutter contre la déforestation et restaurer les écosystèmes dégradés. Nos campagnes de sensibilisation visent à éduquer le public sur l'importance de la protection de l'environnement et à encourager des comportements écologiques. En outre, nous mettons en place des programmes de gestion des déchets et de recyclage pour réduire l'impact environnemental et promouvoir des pratiques durables.",
        activities: [
            "Projets de reboisement et de conservation des forêts.",
            "Campagnes de sensibilisation à la protection de l'environnement.",
            "Programmes de gestion des déchets et de recyclage."
        ],
        image: environnement
    },
];
export const accordionData = [
    {
        id: 0,
        title: "Qu'est-ce que FADI ?",
        desc: "FADI (Fondation Afrique Développement International) est une organisation à but non lucratif dédiée à améliorer les conditions de vie des communautés défavorisées à travers des projets de développement durable."
    },
    {
        id: 1,
        title: "Comment puis-je faire un don à FADI ?",
        desc: "Vous pouvez nous contacter directement pour organiser un don ou obtenir plus d'informations. Contactez-nous à l'adresse email: fadi.africa@gmail.com ou par téléphone au +228 90 07 39 49."
    },
    {
        id: 2,
        title: "Où FADI opère-t-elle ?",
        desc: "FADI opère principalement dans les régions les plus défavorisées d'Afrique."
    },
    {
        id: 3,
        title: "Quels types de projets FADI soutient-elle ?",
        desc: "FADI soutient divers types de projets, y compris l'éducation, la santé, le développement économique, l'accès à l'eau potable, et l'autonomisation des femmes. Chaque projet est conçu pour être durable et avoir un impact à long terme."
    },
    {
        id: 4,
        title: "Puis-je faire du bénévolat avec FADI ?",
        desc: "Oui, FADI accueille les bénévoles qui souhaitent contribuer à nos projets sur le terrain ou aider dans nos bureaux. Veuillez visiter notre section bénévolat sur notre site web pour plus d'informations et pour postuler."
    },
    {
        id: 5,
        title: "Comment FADI utilise-t-elle les dons reçus ?",
        desc: "Les dons reçus par FADI sont utilisés pour financer directement nos projets sur le terrain. Nous nous efforçons de maintenir les frais administratifs aussi bas que possible pour garantir que la majeure partie des fonds va directement aux communautés bénéficiaires."
    },
    {
        id: 6,
        title: "Comment puis-je rester informé des activités de FADI ?",
        desc: "Vous pouvez rester informé de nos activités en consultant notre blog en nous suivant sur les réseaux sociaux."
    },
    {
        id: 7,
        title: "FADI est-elle une organisation transparente ?",
        desc: "Oui, FADI s'engage à la transparence financière et organisationnelle. Nous publions régulièrement des rapports financiers et des mises à jour de projets, et nous sommes audités annuellement par des tiers indépendants."
    }
];


export const objectifsData = [
    {
        id: 1,
        value: "Soutenir et encadrer les initiatives des États africains en faveur du bien-être des populations locales.",
        background:'card border-0 text-center features feature-success feature-clean bg-transparent',
        icon: FaHandsHelping,
        color: 'red'
    },
    {
        id: 2,
        value: "Éveiller la conscience et mobiliser les communautés locales sur les questions de gouvernance.",
        background:'card border-0 text-center features feature-success feature-clean bg-transparent',
        icon: RiSpeakFill,
        color: 'yellow'

    },
    {
        id: 3,
        value: "Favoriser l'émergence d'une culture de non-violence sur le continent.",
        background:'card border-0 text-center features feature-success feature-clean bg-transparent',
        icon: GiPeaceDove,
        color: 'bluw'
    },
    {
        id: 4,
        value: "Organiser des conférences, des tables rondes et des forums sur l'éducation au développement (ED).",
        background:'card border-0 text-center features feature-success feature-clean bg-transparent',
        icon: BsFillCalendarDateFill,
        color: 'green'
    },
]

export const mission = [
    {
        id: 1,
        text: "Notre mission est de promouvoir la formation d'une communauté dynamique et responsable en Afrique, dédiée à soutenir les initiatives de développement communautaire. Nos priorités incluent :",
        values: [
            "Encourager la construction d'infrastructures socio-collectives communautaires couvrant tous les aspects de la vie, tant en milieu rural qu'en milieu urbain en Afrique.",
            "Initier et soutenir des microprojets dans les milieux défavorisés en Afrique.",
            "Faciliter la création d'une plateforme d'échange de connaissances et de circulation d'informations en Afrique."
        ]
    }
    
]

