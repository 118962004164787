import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import Index from './pages';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./assets/scss/style.scss"
import "./assets/css/materialdesignicons.min.css"

import IndexMultiBusiness from './pages/index-multi-business';

import ServicesDetails from './pages/page-service-detail';

import ContactOne from './pages/page-contact-one';

function App() {
  // useEffect(() => {
  //   document.getElementById('theme-opt').href = './css/style.scss'
  // }, [])
  return (
    <>
    <Routes>
      
      <Route path='/' element={<IndexMultiBusiness/>}/>
      <Route path='/home' element={<IndexMultiBusiness/>}/>
      <Route path='/page-service-detail/:id' element={<ServicesDetails/>}/>
      <Route path='/contact' element={<ContactOne/>}/>
      
    </Routes>
    </>
  );
}

export default App;
