import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {MdOutlineArrowForward,FaRegCalendar} from "../assets/icons/vander"

export default function Blog(){

    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch('https://blog.fadi-fondation.org/wp-json/wp/v2/posts?_embed');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const postsData = await response.json();
                setPosts(postsData);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchPosts();
    }, []);


    
    return(
        <div className="row">
            {posts.slice(0,3).map((item,index)=>{
                return(
                    <div className="col-lg-4 col-md-6 mt-4 pt-2" key={index}>
                        <div className="card blog blog-primary shadow rounded overflow-hidden border-0">
                            <div 
                                className="card-img image position-relative overflow-hidden" 
                                style={{
                                    height: '200px'
                                }}
                            >
                                <div className="position-relative overflow-hidden "  style={{
                                    height: '200px'
                                }}>
                                    <img src={item._embedded['wp:featuredmedia'][0].source_url} className="img-fluid"  alt=""/>
                                    <div className="card-overlay"></div>
                                </div>

                                <div className="blog-tag">
                                    <Link to={item.link} className="badge bg-light text-dark">{item.tag}</Link>
                                </div>

                                <div className="read-more">
                                    <Link to={item.link} className="text-white title-dark-50">Continuer à lire <MdOutlineArrowForward/></Link>
                                </div>
                            </div>

                            <div className="card-body content p-0">
                                <div className="p-4">
                                    <Link to={item.link} className="h5 title text-dark d-block mb-0">{item.title.rendered}</Link>
                                    {/* <p className="text-muted mt-2 mb-0">{item.content.rendered}</p> */}
                                </div>

                                <div className="post-meta d-flex justify-content-between p-4 border-top">
                                    <p>{item._embedded.author[0].name}</p>
                                    <Link to="#" className="text-muted readmore d-inline-flex align-items-center"><FaRegCalendar className="me-1"/><span>{item.date.slice(0, 10)}</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}