
import React, { useState } from "react";
import { Link } from "react-router-dom";

import about1 from "../assets/images/business/about01.jpg"
import about3 from "../assets/images/business/about03.jpg"
import bg2 from "../assets/images/business/bg02.jpg"
import backgroundImg from "../assets/images/business/bg01.jpg"

import Navbar from "../componants/indexJs/navbar";
import Blog from "../componants/blog";
import Footer from "../componants/footer/footer";

import { BiLogoFacebook, BiLogoLinkedinSquare, FiMail, MdOutlineArrowForward } from "../assets/icons/vander"
import { featuresData, accordionData, objectifsData } from "../data/data";

import "../../node_modules/react-modal-video/scss/modal-video.scss"

import CountUp from 'react-countup';
import ScrollTop from "../componants/scrollTop";

export default function IndexMultiBusiness() {
    const [activeIndex, setActiveIndex] = useState(0)
    return (
        <>
            <div className="tagline bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <ul className="list-unstyled mb-0">
                                    <li className="list-inline-item mb-0"><Link to="#" className="text-muted fw-normal"><FiMail className="fea icon-sm text-primary" />fadi.africa@gmail.com</Link></li>
                                </ul>

                                <ul className="list-unstyled social-icon tagline-social mb-0">
                                    <li className="list-inline-item mb-0"><Link to="https://www.facebook.com"><BiLogoFacebook className="mb-0 icon" /></Link></li>
                                    <li className="list-inline-item mb-0"><Link to="https://www.linkedin.com/company/fondation-afrique-d%C3%A9veloppement-international/"><BiLogoLinkedinSquare className="mb-0 icon" /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Navbar navClass="defaultscroll sticky tagline-height" manuClass="navigation-menu nav-right nav-light" logoLight={true} />
            <section className="bg-home zoom-image d-flex align-items-center" id="home">
                <div className="bg-overlay image-wrap" id="hero-images" style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: '100%' }}></div>
                <div className="bg-overlay bg-linear-gradient-2"></div>
                <div className="container">
                    <div className="row justify-content-center mt-5">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h4 className="display-5 text-white title-dark mb-4">Fondation Afrique-Dévelopement International</h4>
                                <p className="para-desc text-white-50 mb-0 mx-auto">" Une Fondation pour le mieux-être du citoyen africain "</p>

                                <div className="mt-4 pt-2">
                                    <Link to="/contact" className="btn btn-primary">Travailler avec nous !</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="home-shape-arrow">
                    <Link to=""><i className="mdi mdi-arrow-down arrow-icon text-dark h5 shadow-md"></i></Link>
                </div>

                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="section" id="about">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h6 className="text-primary fw-normal">Qui sommes-nous?</h6>
                                <h4 className="title mb-4">La Fondation Afrique Développement International (FADI) est une fondation portée sur les fonds baptismaux depuis le 28 Novembre 2013 a Lomé (TOGO).</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">
                                    FADI est légalement reconnue par l'Arrété N°0155/MATDCL-CAB le 10 Septembre 2014 et est insérée dans le journal Officiel de la République Togolaise, Numéro Special , N°35 du 18 Novembre 2016.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {featuresData.map((item, index) => {
                            const Icon = item.icon
                            return (
                                <div className="col-lg-4 col-sm-6 mt-4 pt-2" key={index}>
                                    <div className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                                        <div className="icons text-center rounded">
                                            <Icon className="icon d-block rounded h4 mb-0" />
                                        </div>
                                        <div className="content mt-4">
                                            <Link to={`/page-service-detail/${item.id}`} className="h5 text-dark title">{item.title}</Link>
                                            <p className="text-muted mt-3">{item.desc.slice(0, 100)} ...</p>

                                            <Link to={`/page-service-detail/${item.id}`} className="link">Continuer à lire <MdOutlineArrowForward /></Link>
                                        </div>

                                        <div className="feature-ovelay-one"></div>
                                        <div className="feature-ovelay-two"></div>
                                        <div className="feature-ovelay-three"></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="section bg-light" id="">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="about-image position-relative">
                                <img src={about1} className="img-fluid rounded shadow" alt="" />

                                {/* <div className="about-image-position">
                                <img src={about2} className="img-fluid rounded shadow-lg" alt=""/>
                                <div className="play-icon">
                                    <Link to="#!" onClick={() => setOpen(true)} className="play-btn lightbox">
                                        <i className="mdi mdi-play text-primary rounded-circle bg-white shadow-lg"></i>
                                    </Link>
                                </div>
                                <ModalVideo
                                    channel="youtube"
                                    youtube={{ mute: 0, autoplay: 0 }}
                                    isOpen={isOpen}
                                    videoId="yba7hPeTSjk"
                                    onClose={() => setOpen(false)} 
                                />
                            </div> */}
                            </div>
                        </div>

                        <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h6 className="text-primary fw-normal">Notre vision</h6>
                                <h4 className="title mb-4">FADI imagine un monde où le développement durable est à la portée de tous.</h4>
                                {/* <p className="text-muted">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap html page.</p> */}
                                <p className="text-muted mb-0">D'ici 2030, la fondation FADI, sera une institution de promotion des valeurs humaines. Elle sera viable, crédible, dynamique et partenaire dans l'appui / accompagnement des actions des gouvernements africains pour un développement socio-é​​conomique durable des communautés de base ou les femmes, les enfants et les hommes oeuvrent éfficacement avec tous les acteurs de développement pour vivre avec dignité.</p>

                                {/* <div className="mt-4 pt-2">
                                <Link to="#" className="btn btn-primary">Read More <MdOutlineArrowForward/></Link>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mt-60">
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="counter-box position-relative text-center">
                                <h2 className="mb-0 display-2 fw-bold title-dark mt-2 opacity-1"><CountUp start={0} end={30} /></h2>
                                <span className="counter-head fw-normal title-dark position-absolute top-50 start-0 end-0">Gros projets realisés</span>
                            </div>
                        </div>

                        <div className="col-md-3 col-6">
                            <div className="counter-box position-relative text-center">
                                <h2 className="mb-0 display-2 fw-bold title-dark mt-2 opacity-1"><CountUp start={0} end={5} /></h2>
                                <span className="counter-head fw-normal title-dark position-absolute top-50 start-0 end-0">Années d'existence</span>
                            </div>
                        </div>

                        <div className="col-md-3 col-6">
                            <div className="counter-box position-relative text-center">
                                <h2 className="mb-0 display-2 fw-bold title-dark mt-2 opacity-1"><CountUp start={0} end={2} /></h2>
                                <span className="counter-head fw-normal title-dark position-absolute top-50 start-0 end-0">Bureaux dans le monde</span>
                            </div>
                        </div>

                        <div className="col-md-3 col-6">
                            <div className="counter-box position-relative text-center">
                                <h2 className="mb-0 display-2 fw-bold title-dark mt-2 opacity-1"><CountUp start={0} end={56} /></h2>
                                <span className="counter-head fw-normal title-dark position-absolute top-50 start-0 end-0">Actions menées a succès</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mt-60">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-1 order-md-2">
                            <div className="ms-lg-5">
                                <img src={about3} className="img-fluid rounded shadow" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="section-title">
                                <h6 className="text-primary fw-normal">Foire A Questions</h6>
                                <h4 className="title mb-4"> Tout ce que vous devez savoir sur FADI : Consultez notre FAQ.</h4>


                                <div className="accordion mt-4" id="buyingquestion">
                                    {accordionData.map((item, index) => {
                                        return (
                                            <div className="accordion-item rounded shadow mt-2" key={index}>
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className={`${activeIndex === item.id ? "active accordion-button fw-normal border-0 bg-light rounded shadow" : "accordion-button fw-normal border-0 bg-light rounded shadow collapsed"} `} onClick={() => setActiveIndex(item.id)}>
                                                        {item.title}
                                                    </button>
                                                </h2>
                                                {activeIndex === item.id ?
                                                    <div id="collapseOne" className="accordion-collapse border-0 collapse show" >
                                                        <div className="accordion-body text-muted bg-transparent">
                                                            {item.desc}
                                                        </div>
                                                    </div> : ""
                                                }
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section " style={{ backgroundImage: `url(${bg2})`, backgroundAttachment: "fixed", backgroundSize: '100%', backgroundPositionY: '5rem' }} id="cta">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title">
                                <h4 className="title title-dark text-white mb-4">Inspirer, innover, et impacter pour un avenir durable.</h4>
                                <p className="text-light para-dark para-desc mx-auto">Un engagement quotidien pour un développement durable.</p>
                                {/* <Link to="#!" onClick={() => setOpen(true)} className="play-btn border border-light mt-4 lightbox">
                                    <FiPlay className="fea icon-ex-md text-white title-dark" />
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-light" id="news">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-4">Nos objectifs</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">Commencez à collaborer avec la Fondation FADI, qui vous offre tout ce dont vous avez besoin pour promouvoir le bien-être, encourager la gouvernance locale, et favoriser le développement communautaire grâce à nos objectifs suivants :</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {objectifsData.map((item, index) => {
                            const Icon = item.icon
                            return (
                                <div className="col-lg-3 col-md-6 mt-4 pt-2" key={index}>
                                    <div className={item.background}>
                                        <div className="icons bg-lg rounded-lg text-center mx-auto">
                                            <Icon className="d-block icon h2 mb-0" color={item.color} />
                                        </div>
                                        <div className="content mt-4 pt-2">
                                            <p className="text-muted mb-0">{item.value}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                
                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-4">Nos blogs et actus</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">Explorez notre blog pour découvrir des insights approfondis sur le développement communautaire en Afrique et nos initiatives pour promouvoir le bien-être et renforcer la gouvernance locale.</p>
                            </div>
                        </div>
                    </div>
                    <Blog />
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    )
}